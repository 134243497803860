<template>
  <div class="flex flex-col">
    <div class="flex-shrink-0 bg-black-100 text-white">
      <div class="px-6 flex flex-col items-stretch">
        <portal-target name="head" />
      </div>
    </div>
    <div class="flex-grow bg-gray-100 flex flex-col">
      <router-view />
    </div>
    <Notification />
  </div>
</template>

<script>
import Notification from '@/components/Notification.vue';

export default {
  name: 'VendorsPortfolioLayout',
  components: {
    Notification,
  },
};
</script>
